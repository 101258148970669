import styled from "styled-components"
import { shade } from "polished"
import { fade } from '../../../styles/animations'

import { ButtonBase } from "@mui/material"

export const Container = styled.div`
    width: 100%;
    animation: ${ fade } 200ms;

    .MuiGrid-item{
        padding: 15px 7px !important;
    }
`
 
export const FormTitle = styled.header`
    width: 100%;
    padding: 10px 7px;
`

export const FormActions = styled.div`
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button{
        width: 100%;
        max-width: 300px;
    }

    @media(max-width: 750px){
        flex-direction: column-reverse;
        justify-content: center;
    }
`

export const DuplicateButton = styled.button`
    width: 34px;
    height: 34px;
    background-color: #0893f0;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 200ms;

    svg{
        color: #f9f9f9;
    }

    &:hover{
        background-color: ${ shade(0.2, "#0893f0")};
    }

    &:active{
        background-color: ${ shade(0.3, "#0893f0")};
    }
`

export const RemoveButton = styled.button`
    background-color: #E83F5B;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 200ms;

    svg{
        color: #f9f9f9;
    }

    &:hover{
        background-color: ${ shade(0.2, "#E83F5B")};
    }

    &:active{
        background-color: ${ shade(0.3, "#E83F5B")};
    }
`

export const CNPJActions = styled.div`
    position: absolute;
    right: 7px;
    top: 15px;
`

export const SearchSupplierButton = styled(ButtonBase)`
    width: 40px !important;
    height: 40px !important;
    border-radius: 0px 5px 5px 0px !important;
    padding: 2px;
    background-color: ${ props => props.theme["blue-800"] } !important;
    
    svg{
        color: #f9f9f9;
        font-size: 1.5rem;
    }
`

export const ProductsSelection = styled.div`
    height: 40vh; 
    min-height: 300px;
    position: relative;
    margin-bottom: 24px;
    background-color: red;
`