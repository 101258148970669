import styled, { css } from "styled-components"
import { shade } from "polished"
import { fade } from '../../../styles/animations'

import { Fab } from "@mui/material"

interface FormButtonsProps{
    action: "submit" | "cancel" | "close"
    disabled?: boolean
}

export const Container = styled.div`
    width: 100%;
    animation: ${ fade } 200ms;

    .MuiGrid-item{
        padding: 15px 7px !important;
    }

    .unselectable-cost-center{
        background-color: #c2c2c2 !important;
    }
`
 
export const FormTitle = styled.header`
    width: 100%;
    padding: 10px 7px;
`

export const FormActions = styled.div`
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;

    button{
        width: 100%;
        max-width: 300px;
    }

    @media(max-width: 750px){
        flex-direction: column-reverse;
        justify-content: center;
    }
`

export const DuplicateButton = styled.button`
    width: 34px;
    height: 34px;
    background-color: #0893f0;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 200ms;

    svg{
        color: #f9f9f9;
    }

    &:hover{
        background-color: ${ shade(0.2, "#0893f0")};
    }

    &:active{
        background-color: ${ shade(0.3, "#0893f0")};
    }
`

export const RemoveButton = styled.button`
    background-color: #E83F5B;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 200ms;

    svg{
        color: #f9f9f9;
    }

    &:hover{
        background-color: ${ shade(0.2, "#E83F5B")};
    }

    &:active{
        background-color: ${ shade(0.3, "#E83F5B")};
    }
`

export const CNPJActions = styled.div<Omit<FormButtonsProps, "action">>`
    position: absolute;
    right: 7px;
    top: 15px;
    
    button{
        width: 40px;
        height: 40px;
        border-radius: 0px 5px 5px 0px;
        border: none;
        padding: 2px;
        background-color: #2e7d32;
        transition: 200ms;
        
        svg{
            color: #f9f9f9;
            font-size: 1.5rem;
        }

        ${props => props.disabled === false && css`
            &:hover{
                cursor: pointer;
                background-color: ${ shade(0.2, "#2e7d32") };
            }
        `}
    }
`

export const LoadingContainer = styled.div`
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ValidationsInfoButton = styled(Fab)`
    background-color: ${ props => props.theme["blue-800"] } !important;
`

export const ProductsSelection = styled.div`
    height: 40vh; 
    min-height: 300px;
    position: relative;
    margin-bottom: 24px;
`
